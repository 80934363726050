import { Component, OnInit } from '@angular/core';
import { AmcApiService } from '../amc-api.service';

@Component({
  selector: 'app-my-calls-today',
  templateUrl: './my-calls-today.component.html',
  styleUrls: ['./my-calls-today.component.css']
})
export class MyCallsTodayComponent {

  constructor(public amc: AmcApiService) { }

  onClick() {
    this.amc.openMyCallsToday();
  }

}
