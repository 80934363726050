import { Injectable } from '@angular/core';
import * as amc from '@amc-technology/davinci-api';
import { initializeComplete, Logger, openMyCallsToday, setAppHeight, setAppWidth } from '@amc-technology/davinci-api';

@Injectable({
  providedIn: 'root'
})
export class AmcApiService {
  private myCallsTodayUrl = '';
  private openInNewWindow = false;

  constructor() {
    initializeComplete(new Logger('MyCallsToday')).then(config => {
      this.myCallsTodayUrl = <string>config.variables['MyCallsTodayUrl'];
      this.openInNewWindow = <boolean>config.variables['OpenInNewWindow'];
    });

    setAppHeight(24);
    setAppWidth(24);
  }

  openMyCallsToday() {
    openMyCallsToday(this.myCallsTodayUrl, this.openInNewWindow);
  }
}
